import styled from 'styled-components';

const InnerContainer = styled.div`
  background-color: ${(props) =>
    props.isMobileNavOpen ? 'rgba(25, 25, 25, 1)' : 'rgba(25, 25, 25, 0.9)'};
  height: 72px;
  display: flex;
  align-items: center;
  width: 100vw;
  z-index: var(--z-marketingpro-subnav) !important;
  transition: top 0.3s;
  padding: 0px 30px;
  justify-content: center;

  @media (max-width: 1199px) {
    justify-content: space-between;
  }

  p {
    color: var(--white);
    margin: 0;
  }
  ul {
    margin: 0;
  }
  li {
    color: white;
    list-style: none;
    display: inline-block;
    margin: 0 10px;

    a {
      p {
        padding: 12px;
      }
    }
  }

  .sub-nav--logo {
    font-family: var(--font-family-heading);
  }

  .sub-nav--link-active {
    background-color: var(--grey-3);
    border-radius: 4px !important;
    display: block;
    height: 100%;
    p {
      color: var(--darkest);
    }
    &:hover {
      background-color: var(--white);
    }
  }

  .sub-nav--link-inactive {
    color: var(--white);
  }

  .sub-nav--arrow {
    display: none;
    &:hover {
      cursor: pointer;
    }

    @media (max-width: 1199px) {
      display: flex;
    }
  }

  ul {
    display: block;

    @media (max-width: 1199px) {
      display: none;
    }
  }
`;

const Container = styled.div`
  height: 72px;
  width: 100%;
  z-index: 998;
  position: relative;

  .toolbox-sticky-subnav--subscribe {
    height: 40px;
    width: 137px;
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-500);
    border-radius: 30px !important;
    margin-left: 15px;
    padding: 0;

    @media (max-width: 1199px) {
      margin-right: 20px;
    }

    @media (max-width: 575px) {
      width: 121px;
    }
  }

  .toolbox-sticky-subnav--right-content {
    display: flex;
  }
`;

const MobileNav = styled.div`
  background: var(--darkest);
  height: ${(props) => (props.isOpen ? '100vh' : 0)};
  left: 0;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  position: absolute;
  top: 72px;
  width: 100vw;
  z-index: var(--z-marketingpro-subnav) !important;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;

  ul {
    display: none;
    padding: 50px 28px;

    @media (max-width: 1199px) {
      display: block;
      display: ${(props) => (props.isOpen ? 'block' : 'none')};
    }
  }

  li {
    color: var(--white);
    display: block;
    list-style: none;
    margin: 0 auto 20px auto;
    width: max-content;

    p {
      font-size: var(--font-size-18);
    }
  }

  @media (max-width: 1199px) {
    display: block;
  }
`;

export { Container, InnerContainer, MobileNav };

import React, { useEffect, useState } from 'react';
import HyperLink from 'components/Core/HyperLink';
import { globalHistory } from '@reach/router';
import ModalMarketingForm from 'components/Form/ModalMarketingForm';
import Button from 'components/Core/Button';
import Text from 'components/Core/Text';
import upArrow from './images/subnav-up-arrow.svg';
import downArrow from './images/subnav-down-arrow.svg';
import { Container, InnerContainer, MobileNav } from './styles';

const SubNav = ({ data, form }) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(null);
  const [isMarketingModalOpen, toggleIsMarketingModalOpen] = useState(false);
  const [isMobileNavOpen, toggleIsMobileNavOpen] = useState(false);

  useEffect(() => {
    const currentPage = data.items.find((item) => item.link === globalHistory.location.pathname);
    setCurrentPageIndex(data.items?.indexOf(currentPage));
  }, []);

  useEffect(() => {
    if (isMobileNavOpen) {
      document.body.style.overflowY = 'hidden';
      document.body.style.height = '100%';
    } else {
      document.body.style.overflowY = null;
      document.body.style.height = null;
    }

    return () => {
      document.body.style.overflowY = null;
      document.body.style.height = null;
    };
  }, [isMobileNavOpen]);

  const navigation = (
    <ul>
      {data.items.map((item, index) => {
        return (
          <li key={index}>
            <HyperLink
              href={item.link}
              className={index === currentPageIndex ? 'sub-nav--link-active' : ''}
            >
              <p fontSize="small">{item.title}</p>
            </HyperLink>
          </li>
        );
      })}
    </ul>
  );

  return (
    <Container className="toolbox-sticky-subnav">
      <InnerContainer isMobileNavOpen={isMobileNavOpen}>
        {data.logo && (
          <HyperLink href={data.logo?.link}>
            <Text type="p" size="large" className="sub-nav--logo">
              {data.logo?.label}
            </Text>
          </HyperLink>
        )}
        {navigation}
        <div className="toolbox-sticky-subnav--right-content">
          <Button
            className="toolbox-sticky-subnav--subscribe"
            type="secondary"
            onClick={() => toggleIsMarketingModalOpen(true)}
          >
            Get Demo
          </Button>
          <ModalMarketingForm
            isModalOpen={isMarketingModalOpen}
            onModalCancel={() => toggleIsMarketingModalOpen(false)}
            inlineMarketingForm={form}
            onFormSuccess={() => toggleIsMarketingModalOpen(false)}
            formButtonLabel="Request A Demo Today"
          />
          <img
            src={isMobileNavOpen ? upArrow : downArrow}
            className="sub-nav--arrow"
            onClick={() => toggleIsMobileNavOpen(!isMobileNavOpen)}
          />
        </div>
        <MobileNav isOpen={isMobileNavOpen}>{navigation}</MobileNav>
      </InnerContainer>
    </Container>
  );
};

export default SubNav;

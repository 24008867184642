/* eslint-disable import/prefer-default-export */

export const subNavData = {
  items: [
    {
      title: 'Overview',
      link: '/features/pro/marketing',
    },
    {
      title: 'Email',
      link: '/features/pro/marketing/email',
    },
    {
      title: 'Direct Mail',
      link: '/features/pro/marketing/direct-mail',
    },
    {
      title: 'Reputation',
      link: '/features/pro/marketing/reputation',
    },
    {
      title: 'Ads',
      link: '/features/pro/marketing/ads',
    },
  ],
  logo: { link: '/features/pro/marketing', label: 'Marketing Pro' },
};
